export default {
    allUserTypes: state => {
        return state.userTypes
    },
    rowsTable: (state) => {
        return [10, 50, 100, "Tout"]
    },
    allProduct: (state) => {
      return [
        {id: "ABQ", label: "Atlantique Bouclier Quiétude"},
        {id: "APF", label: "Atlantique Prévoyance Familiale"},
        {id: "AHR", label: "Atlantique Horizon Retraite"},
        {id: "AAE", label: "Atlantique Avenir Enfant"},
        { id: "AAF", label: "Tranquilis (Atlantique Assistance Funérailles)" },
      ] 
    },
    allContratStatus: state => {
      return [
        { id: "none", label: "" },
        { id: "active", label: "Actif" },
        { id: "inactive", label: "Inactif" },
        { id: "pendingInactive", label: "Bientôt Inactif" }
      ];
    },

    allPaymentStatus: state => {
      return [
        { id: "late", label: "En retard" },
        { id: "required", label: "Non soldé" },
        { id: "payOff", label: "Soldé" }
      ];
    },
    allCorrespondenceDate: state => {
      return [
        { id: "day", label: "Jour" },
        { id: "year", label: "Année" },
        { id: "month", label: "Mois" }
      ];
    },
    paymentUserTypes: state => {
      return {
        'super_administrator_sys': 'Super Administrateur',
        'administrator_sys': 'Administrateur',
        'gestionnaire_sys': "Gestionnaire",
        'medical_advisor_sys': "Médecin-Conseil",
        'client': 'Client', 
        'broker_inspector': 'Inspecteur',
        'broker_leader': "Chef d'équipe",
        'broker_merchant': 'Commercial',
        'broker_general_agent': 'Agent général',
        'broker': 'Courtier',
        'prospect': 'Prospect',
        'none': "Aucun",
      }
    },
    paymentMethod: state => {
      return [
        { id: "bankCard", label: "Carte bancaire" },
        { id: "check", label: "Chèque" },
        { id: "cash", label: "Espèces" }, 
        { id: "commercialGesture", label: "Geste commercial" },
        { id: "momoCard", label: "Mobile money" },
        { id: "debitAuto", label: "Prélèvement automatique" },
        { id: "virementBank", label: "Virement" },
        { id: "InternalTransfer", label: "Virement interne" }
      ];
    },

    allDocClient: (state) => {
      return {
        ABQ: "admin_Setting_DocClient_atlantiqueBouclierQuiétude",
        APF: "admin_Setting_DocClient_atlantiquePrévoyanceFamille",
        AHR: "admin_Setting_DocClient_atlantiqueHorizonRetraite",
        AAE: "admin_Setting_DocClient_atlantiqueAvenirEnfant",
        AAF: "admin_Setting_DocClient_atlantiqueAssistanceFunérailles",
      }
    },
    allPeriodicityOfPayment: (state) => {
      return [
        { id: "monthly", label: "Mensuelle" },
        { id: "quarterly", label: "Trimestrielle" },
        { id: "biannual", label: "Semestrielle" },
        { id: "annual", label: "Annuelle" },
      ]
    },
}
